import React from "react";
import "../styles/landingPage.css";

export function Loading() {
  return (
    <div className="loadingPage">
      <img src="../assets/images/olhos.png" alt="Loading" />
    </div>
  );
}
