import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { CurrentState } from "../context/currentState.js";
import { CacheApi } from "../context/cacheApi.js";
import "../styles/menu.css";

export default function Menu({ style }) {
  const [selectedMenu, setSelectedMenu] = useState(null);

  const handleMenuClick = (menu) => {
    setSelectedMenu(selectedMenu === menu ? null : menu);
  };

  const { setCurrentSongByAlbum, prevPge } = React.useContext(CurrentState);
  const { initSongsInfo } = React.useContext(CacheApi);

  const navigate = useNavigate();
  const location = useLocation();

  function handleChangeCurrentSong(album_id, destination) {
    setCurrentSongByAlbum(album_id);
    navigate(destination);
  }

  useEffect(() => {
    initSongsInfo();
  }, []);

  useEffect(() => {
    setSelectedMenu(null);
  }, [location]);

  return (
    <div className="menu-container" style={style}>
      <div>
        <Link to="/">
          <h1 className="menu-massaia">MASSAIÁ</h1>
        </Link>

        <Link to={prevPge}>
          {" "}
          <img
            className="menu-exit"
            src="../assets/icons/exit.svg"
            alt="exit"
          />
        </Link>
      </div>
      <div>
        <div className="menu-options">
          <div
            className="menu-main-option"
            onClick={() => handleMenuClick("videos")}
          >
            <p>temas</p>
            <div
              className="menu-option"
              style={{ display: selectedMenu === "videos" ? "block" : "none" }}
            >
              <p>
                <button
                  onClick={() => handleChangeCurrentSong("1", "/temas/1")}
                >
                  volume I
                </button>
              </p>

              <p>
                <button
                  onClick={() => handleChangeCurrentSong("2", "/temas/10")}
                >
                  volume II
                </button>
              </p>
            </div>
          </div>
          <div
            className="menu-main-option"
            onClick={() => handleMenuClick("galeria")}
          >
            <p>galeria</p>
            <div
              className="menu-option"
              style={{ display: selectedMenu === "galeria" ? "block" : "none" }}
            >
              <p>
                <button
                  onClick={() => handleChangeCurrentSong("1", "/galeria/1")}
                >
                  volume I
                </button>
              </p>
              <p>
                <button
                  onClick={() => handleChangeCurrentSong("2", "/galeria/10")}
                >
                  volume II
                </button>
              </p>
            </div>
          </div>
          <div
            className="menu-main-option"
            onClick={() => handleMenuClick("sobre")}
          >
            <p>sobre</p>
            <div
              className="menu-option"
              style={{ display: selectedMenu === "sobre" ? "block" : "none" }}
            >
              <p>
                <Link to="/projeto">projeto</Link>
              </p>
              <p>
                <Link to="/autores">autores</Link>
              </p>
              <p>
                <Link to="/artistas">artistas</Link>
              </p>
              <p>
                <Link to="/site">site</Link>
              </p>
            </div>
          </div>
          <div
            className="menu-main-option"
            onClick={() => handleMenuClick("momentos")}
          >
            <p>
              <Link to="/momentos">momentos</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
